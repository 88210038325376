#galery {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: fixed;
}

#galery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  position: absolute;
}

#galery img.galery--active {
  opacity: 1;
  z-index: 1;
  transition: opacity 1s;
}

/*# sourceMappingURL=index.09b3d33d.css.map */
