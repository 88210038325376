#galery {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        opacity: 0;
    }
    img.galery--active {
        opacity: 1;
        transition: opacity 1s;
        z-index: 1;
    }
}